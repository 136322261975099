import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const icons = [
	'add_box',
	'add_photo',
	'add',
	'alert',
	'all_inclusive',
	'app_blocking',
	'area_chart',
	'arrow_back',
	'arrow_drop_down',
	'arrow_forward',
	'asset-parts-cycle',
	'assets',
	'assignment',
	'assistant_navigation',
	'block',
	'bookmark_border',
	'bookmark',
	'box',
	'build',
	'calendar_clock',
	'calendar_month',
	'camera_take_picture',
	'camera',
	'car-service-inspection',
	'chart-add',
	'chart-disabled',
	'chart',
	'check_box_blank',
	'check_box_checked',
	'check_circle',
	'check_out',
	'check',
	'close_fill',
	'close',
	'cloud_sync',
	'cloud_upload_filled',
	'comment',
	'content_paste_search',
	'dark_mode',
	'dashboard-performance',
	'dashboard',
	'default',
	'delete_forever_outline',
	'delete_forever',
	'delete',
	'directions_car',
	'done',
	'download',
	'edit',
	'email',
	'event',
	'fact_check',
	'fast_forward',
	'fast_rewind',
	'file-pdf',
	'file-zip',
	'filter-remove',
	'filter',
	'forklift',
	'form',
	'format_list_numbered',
	'gps_fixed',
	'gps',
	'hide_source',
	'home',
	'how_to_reg',
	'info_outline',
	'info',
	'kanban-filled',
	'kanban',
	'key',
	'keyboard_arrow_left',
	'keyboard_arrow_right',
	'keyboard_arrow_up',
	'keyboard_double_arrow_left',
	'keyboard_double_arrow_right',
	'label',
	'lightbulb',
	'line_start_circle',
	'link',
	'local_offer',
	'lock-open',
	'lock',
	'logout',
	'map',
	'maps/ticket',
	'marcador-de-mapa',
	'mechanic',
	'more_vert',
	'move',
	'near_me',
	'open_in_new',
	'panel',
	'payments_black',
	'pending_actions',
	'pending',
	'people_alt',
	'person_off',
	'person_pin_circle',
	'person_pin',
	'person_search',
	'person',
	'pin_check',
	'pin_drop',
	'pin_location',
	'preventive-maintenance',
	'push_pin',
	'qr_code',
	'query_stats',
	'radio_button_unchecked',
	'radio_button',
	'redo',
	'replay',
	'report',
	'restaurant',
	'road-stop-signal',
	'route-to-route',
	'route',
	'schedule',
	'schema',
	'search',
	'select-triangle',
	'sentiment_dissatisfied',
	'sentiment_neutral',
	'sentiment_satisfied',
	'settings',
	'share_location',
	'skip_next',
	'skip_previous',
	'sort',
	'sort2',
	'star_border',
	'star',
	'stop-signal',
	'subdirectory_arrow_left',
	'swipe_right',
	'task_alt',
	'telemetry',
	'thumb_up',
	'ticket-2',
	'ticket-3',
	'ticket-status-canceled',
	'ticket-status-done',
	'ticket-status-in-progress',
	'ticket-status-pending',
	'ticket-status-rejected',
	'timer_off',
	'timetable',
	'travel_explore',
	'undo-restart',
	'undo',
	'undo2',
	'upload_file',
	'warn',
	'warning',
	'watch_later',
	'workgroup',
];

@Injectable({
	providedIn: 'root',
})
export class IconSvgService {
	constructor(iconReg: MatIconRegistry, sanitizer: DomSanitizer) {
		icons.map((name) =>
			iconReg.addSvgIcon(
				name,
				sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${name}.svg`)
			)
		);
	}
}
